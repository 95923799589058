<template>
  <div>
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>客户列表</span>
      </div>
      <!--     <div>
             <el-button
                 type="warning"
                 @click="sub"
             >
               提交
             </el-button>
             <el-button
                 type="primary"
                 @click="addSupplier"
             >
               添加客户
             </el-button>
           </div>-->
    </div>
    <!--tab-->
    <div class="tab pt-12">
      <el-tabs v-model="tabActiveName">
        <el-tab-pane name="1">
          <span slot="label">全部</span>
        </el-tab-pane>
      </el-tabs>
      <div class="tab-search">
        <el-input
            placeholder="请输入客户名称"
            v-model="keyWord"
        >
          <i slot="suffix" class="el-icon-search" @click="searchClick"></i>
        </el-input>
      </div>
    </div>
    <!--表格-->
    <div class="supplier-management-table">
      <el-table
          ref="table"
          :data="supplierTable"
          border
          style="width: 100%"
          :row-class-name="tableRowClassName"
          :header-cell-style="{
                      'color': '#18a78a'}"
          v-loading="$store.state.loading"
      >
        <el-table-column
            label="序号"
            type="index"
            width="50"
            align="center"
        >
        </el-table-column>
        <el-table-column
            label="客户名称"
            min-width="160px"
            align="left"
        >
          <template slot-scope="scope">
            {{ scope.row.customer_name }}
            <!--<el-input v-model="scope.row.supplier_name" placeholder="请输入"></el-input>-->
          </template>
        </el-table-column>

        <el-table-column
            label="联系人"
            min-width="120px"
            align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.contacts }}
            <!--<el-input v-model="scope.row.contacts" placeholder="请输入"></el-input>-->
          </template>
        </el-table-column>

        <el-table-column
            label="职务"
            min-width="140px"
            align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.job }}
            <!--<el-input v-model="scope.row.job" placeholder="请输入"></el-input>-->
          </template>
        </el-table-column>

        <el-table-column
            label="手机号"
            min-width="120px"
            align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.phone }}
            <!--<el-input v-model="scope.row.phone" placeholder="请输入"></el-input>-->
          </template>
        </el-table-column>

        <el-table-column
            label="QQ/微信"
            min-width="120px"
            align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.wx }}
            <!--<el-input v-model="scope.row.wx" placeholder="请输入"></el-input>-->
          </template>
        </el-table-column>

        <el-table-column
            label="邮箱"
            min-width="160px"
            align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.email }}
            <!--<el-input v-model="scope.row.email" placeholder="请输入"></el-input>-->
          </template>
        </el-table-column>

        <el-table-column
            label="创建日期"
            prop="creationDate"
            min-width="140px"
            align="center"
        >
        </el-table-column>

        <!--  <el-table-column
              label="操作"
              min-width="90px"
              align="center"
          >
            <template slot-scope="scope">
              <el-button type="danger" @click="del(scope.$index)">删除</el-button>
            </template>
          </el-table-column>-->
      </el-table>
      <div class="footerPage">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="30"
            layout="total, prev, pager, next"
            :total="total"
        >
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import {customerGet} from '@/api/module/customer'

export default {
  components: {},
  props: [],
  data() {
    return {
      tabActiveName: '1',
      total: 123,
      page: 1,
      keyWord: '',//搜索的值
      //添加供应商
      addSupplierTable: {
        supplier_name: '', // 客户名称
        contacts: '',// 联系人
        job: '', // 职务
        phone: '',// 手机号
        wx: '',// QQ/微信
        email: '',// 邮箱
        creationDate: '',//创建日期
      },
      //表格数据
      supplierTable: [
   /*       {
        customer_name: '', // 客户名称
        contacts: '',// 联系人
        job: '', // 职务
        phone: '',// 手机号
        wx: '',// QQ/微信
        email: '',// 邮箱
        creationDate: '',//创建日期
      }*/
      ]
    };
  },
  mounted() {
    this.initLoad();
  },
  methods: {
    // 数据初始化
    initLoad() {
      this.$store.state.loading=true;
      let params = {
        page: this.page,
        type: 1,
        customer_name: this.keyWord,

      }
      customerGet(params).then((result) => {
        console.log(result)
        this.total = result.data.total;
        this.page = result.data.current_page;
        this.supplierTable = result.data.data.map(item => ({
          customer_name: item.customer_name, // 客户名称
          contacts: item.contacts,// 联系人
          job: item.job, // 职务
          phone: item.phone,// 手机号
          wx: item.wx,// QQ/微信
          email: item.email,// 邮箱
          creationDate: this.Utils.timeDate(item.created_at),//创建日期
        }));
      })
    },
    //分页
    handleCurrentChange(val) {
      this.page = val;
      this.initLoad();
    },
    //搜索
    searchClick() {
      this.page = 1;
      this.initLoad();
    },
    //  提交
    sub() {
    },
    //  添加
    addSupplier() {
      let addSupplierTable = {...this.addSupplierTable}
      this.supplierTable.push(addSupplierTable)
    },
    //删除
    del(index) {
      this.$confirm('此操作删除记得提交, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.supplierTable.splice(index, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //表格 第几行显示什么颜色
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
.tab {
  position: relative;

  .tab-search {
    position: absolute;
    right: 5px;
    top: 12px;
    width: 180px;

    i {
      color: #05c69a;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>
